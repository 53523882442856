export const LOGIN_LOADING = 'LOGIN_LOADING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export function loginLoading(loading) {
  return {
    type: LOGIN_LOADING,
    payload: loading
  }
}

export function loginSuccess(data) {
  return {
    type: LOGIN_SUCCESS,
    payload: data
  }
}

export function loginFailure(err) {
  return {
    type: LOGIN_FAILURE,
    payload: err
  }
}

export function login() {
  return function (dispatch) {
    dispatch(loginLoading(true))
    fetch().then((response) => {
      dispatch(loginLoading(false))
      dispatch(loginSuccess(response))
    }).catch((error) => {
      dispatch(loginLoading(false))
      dispatch(loginFailure(error))
    })
  }
}
