import React from 'react';
import Loadable from 'react-loadable';

const Loading = props => {
  const { error } = props
  if (error) {
    return (
      <div>
        <p>{error}</p>
      </div>
    )
  }
  return <div/>
}

const LoadableComponent = (loader, render) => {
  const config = {
    loader,
    loading: Loading,
    delay: 1000
  }
  if (render) {
    config.render = render
  }
  return Loadable(config)
}

export default LoadableComponent
