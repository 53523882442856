import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import store from '../model'
import LoadableComponent from '../componenet/Common/LoadableComponent'

const Home = () => import('../pages/Home/Home')
const ArticleDetail = () => import('../pages/Home/ArticleDetail')
const Interaction = () => import('../pages/Interaction/Interaction')
const InteractionDetail = () => import('../pages/Interaction/InteractionDetail')
const InteractionPublish = () => import('../pages/Interaction/InteractionPublish')
const TreatmentCenter = () => import('../pages/Expand/TreatmentCenter')
const PrizeDetail = () => import('../pages/Mine/PrizeDetail')
const Expand = () => import('../pages/Expand/Expand')
const ShareFileList = () => import('../pages/Expand/ShareFileList')
const QuestionnaireList = () => import('../pages/Expand/QuestionnaireList')
const Questionnaire = () => import('../pages/Expand/Questionnaire')
const AppDescription = () => import('../pages/Mine/AppDescription')
const PrizeList = () => import('../pages/Mine/PrizeList')
const Lottery = () => import('../pages/Mine/Lottery')
const Mine = () => import('../pages/Mine/Mine')
const MineInfo = () => import('../pages/Mine/MineInfo')
const PasswordEdit = () => import('../pages/Mine/PasswordEdit')
const MineCollection = () => import('../pages/Mine/MineCollection')
const Feedback = () => import('../pages/Mine/Feedback')
const Login = () => import('../pages/Home/Login')
const QuestionnaireLogin = () => import('../pages/Home/Questionnaire')
const SecurityReport = () => import('../pages/Expand/SecurityReport')
const NewQuestionnaire = () => import('../pages/Expand/NewQuestionnaire')

export default class RouterDom extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route exact path='/newQuestionnaire' component={LoadableComponent(NewQuestionnaire)} />
            <Route exact path='/securityReport' component={LoadableComponent(SecurityReport)} />
            <Route exact path='/newQuestionnaireEntry' component={LoadableComponent(QuestionnaireLogin)} />
            <Route exact path='/login' component={LoadableComponent(Login)} />
            <Route exact path='/index' component={LoadableComponent(Home)} />
            <Redirect exact from='/' to='/index' />
            <Route exact path='/article/:id' component={LoadableComponent(ArticleDetail)} />
            <Route exact path='/interaction/:id?' component={LoadableComponent(Interaction)} />
            <Route exact path='/interactionDetail/:id/:abc?' component={LoadableComponent(InteractionDetail)} />
            <Route exact path='/interactionPublish' component={LoadableComponent(InteractionPublish)} />
            <Route exact path='/mine' component={LoadableComponent(Mine)} />
            <Route exact path='/treatmentCenter' component={LoadableComponent(TreatmentCenter)} />
            <Route exact path='/prizeDetail/:id' component={LoadableComponent(PrizeDetail)} />
            <Route exact path='/expand' component={LoadableComponent(Expand)} />
            <Route exact path='/questionnaireList/:type' component={LoadableComponent(QuestionnaireList)} />
            <Route exact path='/sharefile/:type?' component={LoadableComponent(ShareFileList)} />
            <Route exact path='/questionnaire/:id' component={LoadableComponent(Questionnaire)} />
            <Route exact path='/description' component={LoadableComponent(AppDescription)} />
            <Route exact path='/prizeList' component={LoadableComponent(PrizeList)} />
            <Route exact path='/lottery' component={LoadableComponent(Lottery)} />
            <Route exact path='/mineInfo' component={LoadableComponent(MineInfo)} />
            <Route exact path='/passwordedit' component={LoadableComponent(PasswordEdit)} />
            <Route exact path='/mineCollection' component={LoadableComponent(MineCollection)} />
            <Route exact path='/feedback' component={LoadableComponent(Feedback)} />
          </Switch>
        </BrowserRouter>
      </Provider>
    )
  }
}
